$brand-link-color: white;
$brand-link-hover-color: white;
$brand-link-background:  #495057;
$brand-link-hover-background:  rgb(109, 7, 26); // rgb(87, 11, 36);
$navbar-link-hover-color: rgb(109, 7, 26);
$navbar-light-hover-color: $navbar-link-hover-color;
$navbar-padding-y: 0;

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25;
$navbar-brand-font-size:            $font-size-lg;
$line-height-base:            1.5;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
//$nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2;
$nav-link-padding-y:                .5rem !default;
$nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
$navbar-brand-height:               $navbar-brand-font-size * $line-height-base - 0.5;
$navbar-brand-padding-y:           0.2em;// ($nav-link-height - $navbar-brand-height) / 5;


$border-radius:               .15rem;
$border-radius-lg:            .3rem !default;
$border-radius-sm:            .2rem !default;

@import url('https://fonts.googleapis.com/css?family=Caladea&display=swap');

$font-family-serif:      Caladea;
$portfolio-thumb-max-size: 300px;
$portfolio-thumb-min-size: 145px;

@import "bootstrap/bootstrap.scss";

$hr-border-light-color:             rgba($white, .1) !default;
$hr-border-light-width:             $border-width !default;

hr.border-light {
  margin-top: $hr-margin-y;
  margin-bottom: $hr-margin-y;
  border: 0;
  border-top: $hr-border-light-width solid $hr-border-light-color;
}
hr.endofpage {
    border-width: 3px;
    width: 80%;
}

.contact .form{
    border: 0;
}

p {
    font-size: $font-size-base;
    margin-bottom: $headings-margin-bottom;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;
}

.ab{
    font-size: .6em;
    font-style: italic;
}

.page-title {
   /* color: $navbar-link-hover-color; */
}
.page-quote {
    font-family: $font-family-serif;
}

.about-title {
    display: block;
    width: 100%;
    height: 200px;
    background: url('../img/DSC2887-n.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;;
}
.bio p{
    margin-left: 10px;
    padding-top: 10px;
}
.portrait{
    
}
.portrait img {
    width: 15em;
    /*height: 10em;*/
}

img.portraitrond {
    border-radius: 50%;
    border: 3px solid $gray-500;
    width: 10em;
    height: 10em;
}
.font-serif {
    font-family: $font-family-serif;
}

.fond_flamant {
    background: url('../img/courrier.jpg') no-repeat center center;
    height: 200px;
    display: block;
}

.portfolio.gallery {
    margin-left: -20px;
    margin-right: -20px;
}
.portfolio.gallery a { float: left; display: block; margin: 0 0 1% 1%; width: 19%; text-align: center; text-decoration: none!important;}
.portfolio.gallery a span { display: block; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; padding: 3px 0;}

.portfolio.gallery a img{
    opacity: 1;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.portfolio.gallery a {
    opacity: 1;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.portfolio.gallery a:hover {
    background-color: #000;
}
.portfolio.gallery a:hover img {
    opacity: 0.8;
}
.portfolio.striped {
    background-size: auto auto;
    background-color: rgba(255, 255, 255, 1);
    background-image: repeating-linear-gradient(0deg, transparent, transparent 250px, rgb(109, 7, 26) 250px, rgb(109, 7, 26) 498px );
}

html, body {
    height: 100%;
    color: $brand-link-background;
}

p {
    font-size: larger; 
}

body.home {
    background: url('../img/DSC7026_rr.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}
body.voile {
    background: linear-gradient(
    rgba(0, 0, 0, 0.45), 
    rgba(0, 0, 0, 0.45)
    ), url('../img/DSC7026_rr.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

body.notfound404{
    background: url('../img/starwars404_r.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

.navbar {
    border-bottom: 1px solid $gray-500;
}
.navbar-nav {
    text-transform: uppercase;
}
.nav-link, .navbar-brand {
    padding-top: 0.3em;
    padding-bottom: 0.3em;
}
.nav-link {
    font-weight: $font-weight-bold;
    margin-bottom: 1px;
    -webkit-transition: color 0.5s ease-out;
    -moz-transition: color 0.5s ease-out;
	-o-transition: color 0.5s ease-out;
    -ms-transition: color 0.5s ease-out;
    transition: color 0.5s ease-out;
}
.navbar-author {
    margin-right: 0.5em;
}
.nav-link:hover {
    color: $navbar-link-hover-color;
    border-bottom: 2px solid $navbar-link-hover-color;
    margin-bottom: -1px;
}

.nav-item a:hover {
    color: $navbar-link-hover-color;
}

.social-icons a {
    display: inline-block;
    height: 2rem;
    width: 2rem;
    background-color: $brand-link-background;
    color: $brand-link-color;
    border-radius: 100%;
    text-align: center;
    font-size: 1rem;
    line-height: 2rem;
    margin-right: 0.1rem;
    -webkit-transition: background 0.5s ease-out;
    -moz-transition: background 0.5s ease-out;
	-o-transition: background 0.5s ease-out;
    -ms-transition: background 0.5s ease-out;
    transition: background 0.5s ease-out;
}
.social-icons a:last-child {
    margin-right: 0.5em;
}
.social-icons a:hover {
    color: $brand-link-hover-color;
    background-color: $brand-link-hover-background;
}
.social-link:hover {
    color: rgba($brand-link-color, 0.7);
}

#thankyou p {
   font-size:  2em;
}

@include media-breakpoint-up(md) {
    .navbar-nav a {
        padding-top: 1em;
        padding-bottom: 1em;
    }
 
    .nav-separator {
        font-weight: bold;
        font-size: 20px;
        padding-top: 11px;
    }
    .portrait img {
        width: 28em;
        /*height: 10em;*/
    }
    .bio p{
        margin-left: 20px;
        padding-top: 20px;
    }
}